/* eslint-disable jsx-a11y/no-noninteractive-element-interactions  */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { h } from 'preact';
import { UIPlugin } from '@uppy/core';
import FadeIn from "./FadeIn.js";
import TransitionGroup from "./TransitionGroup.js";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore We don't want TS to generate types for the package.json
const packageJson = {
  "version": "4.1.1"
};
/**
 * Informer
 * Shows rad message bubbles
 * used like this: `uppy.info('hello world', 'info', 5000)`
 * or for errors: `uppy.info('Error uploading img.jpg', 'error', 5000)`
 *
 */
export default class Informer extends UIPlugin {
  constructor(uppy, opts) {
    super(uppy, opts);
    this.render = state => {
      return h("div", {
        className: "uppy uppy-Informer"
      }, h(TransitionGroup, null, state.info.map(info => h(FadeIn, {
        key: info.message
      }, h("p", {
        role: "alert"
      }, info.message, ' ', info.details && h("span", {
        "aria-label": info.details,
        "data-microtip-position": "top-left",
        "data-microtip-size": "medium",
        role: "tooltip",
        onClick: () =>
        // eslint-disable-next-line no-alert
        alert(`${info.message} \n\n ${info.details}`)
      }, "?"))))));
    };
    this.type = 'progressindicator';
    this.id = this.opts.id || 'Informer';
    this.title = 'Informer';
  }
  install() {
    const {
      target
    } = this.opts;
    if (target) {
      this.mount(target, this);
    }
  }
}
Informer.VERSION = packageJson.version;